<template>
  <section>
    <b-row class="header justify-content-between">
      <b-row>
        <b-col cols="2">
          <router-link
            to="/configuracoes-da-clinica"
            tag="span"
            class="path"
          >
            <ArrowLeft class="icon"/>
          </router-link>
        </b-col>
        <b-col cols="10">
          <Ellipsis>
            <h3>Nova configuração</h3>
          </Ellipsis>
        </b-col>
      </b-row>
      <b-button
        variant="primary"
        :disabled="loading"
        @click="updateConfig"
      >
        Atualizar configuração
        <b-spinner
          v-if="loading"
          small
          variant="light"
        />
      </b-button>
    </b-row>
    <b-row class="justify-content-between mt-3">
      <b-col>
        <b-row>
          <b-col>
            <strong>Tipo de despesa</strong>
            <Ellipsis>
              {{ 
                tissMatMedConfig.expense_types
                ? tissMatMedConfig.expense_types?.join(' e ') 
                : '-'
              }}
            </Ellipsis>
          </b-col>
          <b-col>
            <strong>Tabela de preço</strong>
            <p>
              {{ 
                tissMatMedConfig?.table
                ? tissMatMedConfig?.table.toUpperCase() 
                : '-'
              }}
            </p>
          </b-col>
          <b-col>
            <strong>Formação de preço</strong>
            <p>{{ tissMatMedConfig?.price_formation }}</p>
          </b-col>
          <b-col>
            <strong>Inflator/deflator</strong>
            <p>{{ tissMatMedConfig?.inflator_deflator }}%</p>
          </b-col>
          <b-col>
            <strong>Data vigente</strong>
            <p>{{ parseDate(tissMatMedConfig.initial_date) }}</p>
          </b-col>
          <b-col>
            <strong>Atualização</strong>
            <p>{{ parseDate(tissMatMedConfig.updated_at) }}</p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { parseDate } from '@/utils/dateHelper'
export default {
  name: 'pageHeader',
  components: {
    Ellipsis: () => import('@/components/General/Ellipsis.vue'),
    ArrowLeft: () => import('@/assets/icons/arrow-left.svg'),
  },
  props: {
    loading: Boolean,
    tissMatMedConfig: {
      type: Object,
      required: true,
    },
  },
  methods: {
    parseDate,
    updateConfig() {
      this.$emit('update')
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  h3 {
    color: var(--dark-blue, #0C1D59);
    font-size: 18px;
    font-family: Red Hat Display;
    font-weight: 700;
    line-height: 134%;
    letter-spacing: -0.18px;
  }
}

strong {
  color: var(--dark-blue, #0C1D59);
  font-size: 13px;
  font-family: Nunito Sans;
  font-weight: 700;
  line-height: 130%;
}
</style>